import { passwordPattern } from '@/utils/global-func';
import FormItem from 'antd/es/form/FormItem';
import InputPassword from '..';

interface IFormPasswordItemProp {
  name: string;
  label?: string;
  onChange?: any;
}

const FormPasswordItem = (props: IFormPasswordItemProp) => {
  const { name, label = 'Password', onChange } = props;

  return (
    <FormItem
      label={label}
      name={name || 'password'}
      rules={[
        { required: true, message: 'Please enter your new password' },
        {
          pattern: passwordPattern,
          min: 8,
          message: (
            <div className='flex max-w-[416px] flex-col gap-2 text-wrap'>
              Password is at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special charact!
              <div>E.g.&nbsp;Abc@12345</div>
            </div>
          ),
        },
      ]}
    >
      <InputPassword onChange={onChange} size='large' placeholder='Enter your password' />
    </FormItem>
  );
};

export default FormPasswordItem;
