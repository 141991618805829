'use client';

import { Input } from 'antd';
import { InputProps } from 'antd/es/input';
import React from 'react';

interface IInputPasswordProps extends InputProps {}

const InputPassword = React.forwardRef((props: IInputPasswordProps, ref: any) => {
  return <Input.Password {...props} ref={ref} />;
});

export default InputPassword;
