'use client';

import { authApi } from '@/api-client';
import PasswordFormInput from '@/components/elements/FormItem/FormPasswordItem';
import { useAuth } from '@/hooks/auth.hook';
import { LoginRoleKey } from '@/interface/enum';
import { defaultHeadPhone, numberPrefixOptions } from '@/utils/phone';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, message, Modal, notification, Select, Space, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import ArrowRight from '../../../../public/images/ArrowRight.png';
import LoginImage from '../../../../public/images/login-image.webp';
import LogoForm from '../../../../public/images/Logo.png';

const LoginPage = () => {
  const { login } = useAuth();
  const [form] = useForm();
  const [formLogin] = useForm();
  const [open, setOpen] = React.useState(false);
  const [emailOrPhone, setEmailOrPhone] = React.useState('Email');
  const [resetSctive, setResetActive] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [headPhone, setHeadPhone] = React.useState('');
  const [showResend, setShowResend] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  // /auth/confirm-mail
  const confirmMailMutation = async (email: string) => {
    try {
      await authApi.confirm_mail({ email: email });
      notification.success({ message: 'Mail OTP', description: 'Mail OTP Send Success' });
      if (!showResend) {
        setEmail(email);
      }
      setResetActive(true);
      setShowResend(true);
    } catch (err: any) {
      notification.error({
        message: 'Mail OTP',
        description: `${err?.response?.data?.message}`,
      });
    }
  };

  // Retrieve Mutations
  const retrieveMutation = useMutation({
    mutationFn: async (data: any) => {
      await authApi.retrieve_password({ ...data });
    },
    onSuccess: () => {
      notification.success({ message: 'Password', description: 'Change Success' });
      onClear();
    },

    onError: (err: any) => {
      notification.error({ message: 'Password fail', description: `${err?.response?.data?.message}` });
    },
  });

  const resetSubmit = (value: any) => {
    if (!resetSctive) {
      confirmMailMutation(value.email);
    } else {
      retrieveMutation.mutate(value);
    }
  };

  const submitLogin = async (value: any) => {
    setLoading(true);
    await new Promise(r => setTimeout(r, 2000));

    if ((phone || headPhone) && emailOrPhone !== 'Email') {
      const merge = `${headPhone || defaultHeadPhone}${phone}`;
      login({
        email: '',
        phone: merge,
        password: value.password,
        role: LoginRoleKey.CUSTOMER,
      });
    } else {
      login({
        phone: '',
        email: value.Username,
        password: value.password,
        role: LoginRoleKey.CUSTOMER,
      });
    }
    setLoading(false);
  };

  const onClear = () => {
    form.resetFields();
    formLogin.resetFields();
    setOpen(false);
  };

  // const loginMutation = useMutation({
  //   mutationFn: (data: any) => {
  //     return authApi.login(data);
  //   },
  //   onSuccess: () => {
  //     router.push('/');
  //   },

  //   onError: (error: any) => {
  //     notification.error({ message: 'Password', description: `${error?.response?.data?.message}` });
  //     formLogin.setFieldValue('password', '');
  //   },
  // });

  const watchEmail = Form.useWatch('email', form);
  const watchCode = Form.useWatch('code_confirm', form);

  const checkValidity = async () => {
    try {
      if (!watchEmail) return message.error('Email is not Empty');
      const errors = form.getFieldError('email'); // 'field1' là tên của form item
      const values = await form.validateFields(['email']);

      if (errors.length === 0 && values) {
        confirmMailMutation(values.email);
      } else {
        message.error('Email not valid, please change');
      }
    } catch (error) {
      message.error('Email not valid, please change');
    }
  };

  return (
    <section className='flex min-h-screen items-center justify-center rounded-xl bg-custom-gradient p-6'>
      <div className='mx-auto flex flex-1 items-center gap-2 rounded-lg bg-white p-8 shadow-md sm:flex-none'>
        {/* form */}
        <div className='mx-auto flex w-full flex-col gap-3 px-4 sm:max-w-[400px]'>
          <div className='relative flex h-[90px] w-[84px] self-center pb-4'>
            <a href='/'>
              <Image src={LogoForm} alt='form logo' fill className='object-contain' />
            </a>
          </div>

          {/* <Button type='default' size='large'>
            <Image src={GoogleSGV} alt='gg svg' width={24} height={24} className='object-contain' />
            <span className='font-medium'>Sign in with Google</span>
          </Button> */}

          {/* <Divider>
            <span className='text-[14px] text-gray-400'>Or sign in with email</span>
          </Divider> */}

          <div>
            If you don't know the phone number or email address, you can&nbsp;
            <button
              onClick={() => {
                setEmailOrPhone('Email');
                formLogin.resetFields();
              }}
              className='cursor-pointer font-medium text-[#1677FF] underline'
            >
              login with email
            </button>
            &nbsp;or &nbsp;
            <button
              onClick={() => {
                setEmailOrPhone('Phone');
                formLogin.resetFields();
              }}
              className='cursor-pointer font-medium text-[#1677FF] underline'
            >
              login with phone number
            </button>
          </div>

          <Form form={formLogin} layout='vertical' className='flex flex-col' onFinish={submitLogin}>
            {emailOrPhone === 'Email' ? (
              <FormItem
                label='Email'
                name={'Username'}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid e-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your e-mail!',
                  },
                ]}
              >
                <Input size='large' placeholder='Enter your email' />
              </FormItem>
            ) : (
              <FormItem
                label='Phone'
                name={'Username'}
                rules={[
                  { required: true, message: 'Please enter your phone' },
                  {
                    validator: (_, value) => {
                      const phonePattern = /^[0-9]{10,15}$/; // Example pattern for 10-15 digits
                      if (!value || phonePattern.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter a valid phone number.'));
                    },
                  },
                ]}
                className='!w-full'
              >
                <Space direction='vertical' className='!flex !w-full !max-w-full !flex-1'>
                  <Space.Compact style={{ width: '100%' }} className='!flex !w-full !max-w-full !flex-1'>
                    <Select
                      size='large'
                      style={{ width: '30%' }}
                      defaultValue={defaultHeadPhone}
                      onChange={val => setHeadPhone(val)}
                      options={numberPrefixOptions}
                    />

                    <Input
                      className='!w-[70%] !flex-1'
                      onChange={e => setPhone(e?.target?.value)}
                      size='large'
                      placeholder='Enter your phone'
                    />
                  </Space.Compact>
                </Space>
              </FormItem>
            )}

            <PasswordFormInput name='password' />

            <div className='flex justify-end gap-4 pt-2'>
              <div
                onClick={() => setOpen(true)}
                className='line-clamp-1 cursor-pointer text-sm font-normal text-yellow-600'
              >
                Forgot password?
              </div>
            </div>

            <Modal
              title='Reset your password'
              open={open}
              onClose={() => {
                setOpen(false);
                form.resetFields();
              }}
              onCancel={() => {
                setOpen(false);
                form.resetFields();
              }}
              footer={[]}
            >
              <div className='flex flex-col gap-4'>
                <div className='self-center text-[15px] font-medium'>Enter your email to reset your password</div>

                <Form form={form} layout='vertical' onFinish={resetSubmit}>
                  <div className='flex flex-col justify-end'>
                    <FormItem
                      label='Email'
                      name={'email'}
                      rules={[
                        { required: true, message: 'Please enter your email' },
                        { type: 'email', message: 'Please enter valid email' },
                      ]}
                    >
                      <Input onChange={() => form.setFieldValue('code_confirm', '')} />
                    </FormItem>

                    <Button
                      disabled={!watchEmail}
                      type='link'
                      size='large'
                      className='group w-fit self-end'
                      onClick={checkValidity}
                    >
                      Resend code
                      {watchEmail && (
                        <span className='relative size-5'>
                          <Image
                            className='object-contain transition-transform group-hover:translate-x-2 group-hover:scale-x-110'
                            alt='arrow right'
                            src={ArrowRight}
                            sizes='20px'
                            fill
                          />
                        </span>
                      )}
                    </Button>
                  </div>

                  {/* <FormItem
                    label='Code confirm'
                    name={'code_confirm'}
                    // rules={[{ required: true, message: 'Please enter your code form your email' }]}
                  >
                    <Tooltip title={!watchEmail ? 'Please enter your email' : ''}>
                      <Input disabled={!watchEmail} />
                    </Tooltip>
                  </FormItem> */}

                  <Tooltip title={!watchEmail ? 'Please enter your email' : ''}>
                    <FormItem label='Code Confirm' name={'code_confirm'}>
                      <Input />
                    </FormItem>
                  </Tooltip>

                  <PasswordFormInput name='new_password' label='New password' />

                  <Tooltip title={!watchCode ? 'Please enter your code to submit' : ''}>
                    <Button disabled={!watchCode} htmlType='submit' className='w-full' type='primary'>
                      Submit
                    </Button>
                  </Tooltip>
                </Form>
              </div>
            </Modal>

            <FormItem>
              <Button
                loading={loading}
                className='mt-3 w-full !rounded-xl'
                size='large'
                type='primary'
                htmlType='submit'
              >
                Submit
              </Button>
            </FormItem>
          </Form>

          <div className='-mt-6'>
            Not register yet?&nbsp;
            <Link href={'/register'} className='text-yellow-600'>
              Create an account
            </Link>
          </div>
        </div>

        <div className='relative hidden h-[530px] min-w-[400px] rounded-xl md:flex'>
          <Image alt='logo image' src={LoginImage} fill className='rounded-lg object-cover' />
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
